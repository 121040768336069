.menu-list {
    width: 220px;
}

.menu-item {
    font-weight: 450;
    font-size: 15px;
    height: 100%;
}


.list-item {
    height: 42px;
}