.inner-container {
    display: flex;
    align-items: center;
    min-height: inherit;
    min-width: inherit;
}

.primary {
    justify-content: space-between;
    flex-direction: row;
}

