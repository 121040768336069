.header-main {
    width: 100%;
    min-height: 70px;   
    font-family: var(--font-family);
}

.nav-wrapper {
    background-color: #222222;
}

.nav-scroll {
    background-color: #222222;
    z-index: 1000;

}

.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-item {
    font-family: 'Manrope';
}

.logo {
    background: rgba(0, 0, 0, 0.3);
}

.hero-gradient {
    background: rgba(0, 0, 0, 0.3);
}