.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 84px;

    padding: 9px 16px;
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
    // background-color: var(--white);
    border: 1px solid transparent;
    user-select: none;

    + .wrapper {
        margin-left: 16px;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.icon + .title,
.title + .icon {
    margin-left: 8px;
}

.image + .title,
.title + .image {
    margin-left: 8px;
}

// Button types CSS
.primary {
    background-color: #7ed957;
    color: var(--white);
    font-weight: 600;
    font-size: .9em;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: -0.028px;
    min-width: 1px;
    justify-content: center;
    padding: 8px;
    border-radius: 4px;
    
    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),#7ed957;
    }
}

.text {
    border-radius: 0;
    font-weight: 700;
    font-size: .9em;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: -0.028px;
    min-width: 1px;
    justify-content: flex-start;
    padding: 8px;
    margin: 0 10px;
    color: #fff;

    @keyframes sparkleAnimation {
        0% { color: #7ed957; }
        50% { color: #b7eb34; }
        100% { color: #7ed957; }
    }
    
    &:hover {
        animation: sparkleAnimation 1s infinite;
    }

    // &:hover::after {
    //     content: '';
    //     position: absolute;
    //     background-color: #eebd00;
    //     width: 50px;
    //     height: 2px;
    //     top: 93px;
    // }

}


.text-black {
    border-radius: 0;
    font-weight: 600;
    font-size: .9em;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: -0.028px;
    min-width: 1px;
    justify-content: flex-start;
    padding: 8px;
    color: #000;


    &:hover {
        background-color: #7ed957;
    }

}


.image, .icon {
    width: 32px;
    text-align: center;
}

.text-outline {
    border-color:rgba(22, 24, 35, 0.12);
    border-radius: 2px;

    &:hover {
        background-color: rgba(22, 24, 35, .03);
    }
}

.rounded {
    border-radius: 999px;
    color: rgba(22, 24, 35, .75);
    border-color: rgba(22, 24, 35, .12);
    background-color: rgba(255, 255, 255, 1);
}

.primary-non-border {
    background-color: #7ed957;
    color: #fff;
    height: 38px;
    font-size: 16px;
    line-height: 1.5rem;
}

.primary-rounded {
    background-color: #7ed957;
    color: var(--white);
    font-weight: 600;
    font-size: .9em;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: -0.028px;
    min-width: 1px;
    justify-content: center;
    padding: 8px;
    border-radius: 999px;
    
    
    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)),#7ed957;
    }
}

.outline {
    color: rgba(254, 44, 85, 1);
    border-color: rgba(254, 44, 85, 1);

    &:hover {
        background-color: rgba(254, 44, 85, 0.06);;
    }
}

.scroll {
    padding: 4px;
    min-width: 36px;
    min-height: 36px;
    background-color: #d7d6d6;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #c7bfbf;
        bottom: 0;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .2s ease-in-out;
    }

    &:hover::before {
        transform-origin: left;
        transform: scaleX(1);
    }

    .icon {
        z-index: 1000;
    }
   
}

//Button sizes CSS
.small {
    min-width: 88px;
    padding: 4px 16px;
}

.large {
    padding: 14px 16px;
    min-width: 140px;
}