@import 'normalize.css';
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --font-family: "Roboto", "Jost", "Manrope", sans-serif;
    --primary: #b7eb34;
    --black: #000;
    --white: #fff;
    --text-color: #333;

    //Default Layout
    --default-layout-header-height: 60px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

