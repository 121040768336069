.icon, .image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    position: relative;
    background: center no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    border-radius: 50%;
    padding: 3px;
    border-width: 1px;
    font-size: 18px;

}

