.footer-main {
    background-color: #3a3a3c;
    min-height: 263px;
}

.contact-info {
    text-align: center;
    color: #fff;
}

//Content Container
.content-container {
    min-height: inherit;
    padding: 0 10px 30px 10px;
}

.wrapper {   
    align-items: center;
    min-width: inherit;
    min-height: inherit;
    padding-top: 30px;
}

.title {
    display: flex;
    justify-content: center;

    span {
        text-transform: uppercase;
        font-weight: 500;
        color: #fff
    }
}

.section-break {
    
    display: flex;
    justify-content: center;
    height: 12px;
    min-width: inherit;
    margin: 16px 0 24px 0;

    span {
        height: 100%;
        min-width: 250px;
        box-shadow: #eae4e41f 0px 1px 0px;
    }

}

.image {
    width: 180px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
}